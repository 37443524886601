import { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { SessionContext } from "../../context/session"
import { DiscordIcon } from "../Icons"
import { supabase } from "../supabase"

export const DiscordAuth = () => {
    const setSesstion = useContext(SessionContext)
    const history = useHistory()
    useEffect(() => {
        supabase.auth.onAuthStateChange(async (event, session) => {
            setSesstion.dispatch({
                type:event,
                user_data: session ? session.user.user_metadata : null,
            })
            if(event === 'SIGNED_IN'){
                history.push('/')
            }
        })
    },[])

    const signin_discord = async () => {
        
        const {user, session, error } = await supabase.auth.signIn(
            {
                provider:'discord'   
            },
            {
                redirectTo: document.location.origin
            }
        )
        console.log(user)
        console.log(session)
        console.log(error)
    }

    return (
        <button
            onClick={signin_discord}
            className="
                flex items-center md:justify-between justify-center
                space-x-10 md:space-x-0 px-7
                w-10/12 h-16
                bg-discordPrimary hover:bg-discordHover active:bg-discordActive
                transition duration-200 ease-in-out
                rounded-lg text-lg shadow-lg
            "
        >
            <DiscordIcon/>
            <p className="text-center font-bold">
                Zaloguj za pomocą Discorda
            </p>
        </button>
    )
}