import React from 'react'
import { useTable } from 'react-table'
import { Burger } from './Icons'

export const VideoList = () => {
    
    
    const head = ['Miniaturka', 'Tytuł']
    const data = []
    /*
    const data = React.useMemo(
        () => [
            {
                title: '[shisha] Kageki Shoujo - 07 PL',
                thumb: 'https://icdn.2cda.pl/vid/thumbs/2e098dcafdf405690a4fe13b100f5269-4981.jpg_ooooxooxox_1280x720.jpg',
                duration: '00:23:40',
                cda_id: '818190246',
                laid_id: '818190246',
            },
            {
                title: '[PaMu] Slime Taoshite 300-nen, Shiranai Uchi ni Level Max ni Nattemashita! - 01 PL 1080p',
                thumb: 'https://icdn.2cda.pl/vid/thumbs/4b6913f8c837abade52b6d44281a8359-3321.jpg_ooooxooxox_1280x720.jpg',
                duration: '00:23:40',
                cda_id: '724264688',
                laid_id: '724264688',
            },
            {
                title: '[shisha] Tokyo Revengers - 20 PL (uncensored)',
                thumb: 'https://icdn.2cda.pl/vid/thumbs/9c68b8e43e929073a33102655a6ecbf1-7471.jpg_ooooxooxox_1280x720.jpg',
                duration: '00:23:41',
                cda_id: '8200745e2',
                laid_id: '8200745e2',
            },
        ],
        []
      )
      */
    

    const Thumb = ({thumb, duration}) => {
        return (
            <div className="relative min-w-32 ">
                <img className="rounded-lg" src={thumb}/>
                <div className="absolute text-white bottom-1 right-1" >
                    <p className="bg-black bg-opacity-70 px-2 py-1 text-xs rounded-md font-semibold">
                        {duration}
                    </p>
                </div>
            </div>
        )
    }    

    const Title = ({title}) => {
        return (
            <div className="min-w-64 truncate">
                {title}
            </div>
        )
    }

    const Views = ({views}) => {
        return (
            <span className="text-center">
                {views} wyświetleń
            </span>
        )
    }

    const Added = ({date}) => {
        return (
            <div>
                {date} 
            </div>
        )
    }

    const CDA = ({cda_id}) => {
        return (
            <a href={`https://cda.pl/video/${cda_id}`} target="_blank">
                <button className="w-20 h-8 rounded-lg flex justify-center items-center font-bold text-2xl text-white bg-red-600 hover:bg-red-700" >
                    CDA
                </button>
            </a> 
        )
    }
    const Laid = ({laid_id}) => {
        return (
            <a href={`https://laid.pamu.me/video/${laid_id}`} target="_blank">
                <button className="w-20 h-8 rounded-lg flex justify-center items-center font-bold text-2xl text-white bg-pink-400 hover:bg-pink-500" >
                    Laid
                </button>
            </a> 
        )
    }

    const Edit = () => {
        return (
            <button className="
                text-center font-semibold 
                bg-black dark:bg-white 
                dark:bg-opacity-10 bg-opacity-10 
                dark:hover:bg-opacity-20 hover:bg-opacity-20 
                rounded-lg px-2 py-1
            ">
                Edytuj
            </button>
        )
    }

    const Stats = () => {
        return (
            <button className="
                    text-center font-semibold 
                    bg-black dark:bg-white 
                    dark:bg-opacity-10 bg-opacity-10 
                    dark:hover:bg-opacity-20 hover:bg-opacity-20 
                    rounded-lg px-2 py-1
                ">
                Statystyki
            </button>
        )
    }

    return (
        <div className="w-full h-full flex flex-col" >
            <div className="h-24 flex flex-row items-center">
                <p className="text-4xl font-bold h-full w-64 flex items-center pl-5">
                    Filmy
                </p>
                <button className="ml-auto bg-red-600 h-12 w-32 mr-10 rounded-lg font-bold text-lg">
                    Dodaj film
                </button>
            </div>
            <div className="flex flex-col dark:text-white text-black font-semibold">
            {data.map(({title, thumb, duration, cda_id, laid_id}) => {
                return (
                    <div style={{
                        maxHeight:'120px'
                        
                    }}
                    key={cda_id}
                    className="
                        dark:hover:bg-white dark:hover:bg-opacity-8 
                        hover:bg-black hover:bg-opacity-8 
                        p-2 
                        lg:grid gap-4 lg:grid-cols-2x1fr 
                        flex
                        items-center
                    "
                    >
                            <div style={{
                                display:'grid',
                                gridTemplateColumns: '0.5fr 3fr',
                                alignItems:'center',
                            }}
                                className="gap-4"
                            >
                                <Thumb thumb={thumb} duration={duration} />
                                <Title title={title} />
                            </div>
                            <div style={{
                                gridTemplateColumns: '2fr 0.5fr 0.5fr 0.5fr 0.5fr',
                                alignItems:'center',
                            }}
                                className="lg:grid hidden gap-4"
                            >
                                <Views views={'1285'} />
                                <Edit/>
                                <Stats/>
                                <CDA cda_id={cda_id}/>
                                <Laid laid_id={laid_id}/>
                            </div>
                            <button className="lg:hidden mr-5 flex items-center justify-center">
                                <Burger/>
                            </button>
                    </div>
                )
            })}


            </div>
        </div>
    )
}