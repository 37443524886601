import { createClient } from "@supabase/supabase-js"
import { Fragment, useEffect, useRef, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Burger, EditIcon, ExternalLink, Trash } from "./Icons"
import { PlaylistEdit } from "./PlaylistEdit"
import { supabase } from "./supabase"

export const PlaylistList = () => {


    const history = useHistory()
    const [playlists, setPlaylists] = useState(null)
    const [editing, setEditing] = useState(false)
    const [editing_index, setEditing_Index] = useState(0)

    const supabase_get_data = async () => {
        const {data, error} = await supabase
            .from('playlist')
            .select(`
                id,
                name,
                items:playlist_video (
                    id, thumb
                )
            `,{},{count:'exact'})
        
        setPlaylists(data)
    }

    useEffect(() => {
        supabase_get_data()
    },[])

    const Edit = ({idx, id}) => {
        return (
            <Link to={`/playlist/${id}`}>
                <button 
                    className="flex items-center justify-center"
                >
                    <EditIcon/>
                </button>
            </Link>
        )
    }


    const DeleteButton = ({id, name, o}) => {

        const d = async (id, name, o) => {

            if(window.confirm(`Chcesz skasować playliste ${name}`)){
                const {data, error} = await supabase
                    .rpc('delete_playlist', {p_id:id})
                if(error) throw error
                let v = playlists
                v.splice(o,1)
                setPlaylists(v)
            }
        }



        return (
            <button
                onClick={() => d(id, name, o)}
                className="flex items-center justify-center"
            >
                <Trash/>
            </button>
        )
    }

    const Count = ({count}) => {
        return (
            <span className="text-center  font-semibold  dark:text-white text-black">
                {count} filmy
            </span>
        )
    }


    const Thumb = ({thumb, duration}) => {
        return (
            <div className="relative min-w-32 ">
                <img className="rounded-lg" src={thumb ? thumb : 'https://i.imgur.com/Q7uHHjE.png'}/>
                <div className="absolute text-white bottom-1 right-1" >
                    {duration ? <p className="bg-black bg-opacity-70 px-2 py-1 text-xs rounded-md font-semibold">
                        {duration}
                    </p> : null}

                </div>
            </div>
        )
    }    

    const Title = ({title}) => {
        return (
            <div className="min-w-64 truncate font-semibold  dark:text-white text-black">
                {title}
            </div>
        )
    }


    const PlaylistRow = ({x, idx}) => {
        console.log(x)
        return (
            <div style={{
                maxHeight:'120px'
                
            }}
            key={x.id}
            className="
                dark:hover:bg-white dark:hover:bg-opacity-8 
                hover:bg-black hover:bg-opacity-8 
                p-2 
                lg:grid gap-4 lg:grid-cols-2x1fr 
                flex
                items-center
            "
            >
                    <div style={{
                        display:'grid',
                        gridTemplateColumns: '0.5fr 3fr',
                        alignItems:'center',
                    }}
                        className="gap-4"
                    >
                        <Thumb thumb={x.items[0]?.thumb} />
                        <Title title={x.name} />
                    </div>
                    <div style={{
                        gridTemplateColumns: '2fr 0.5fr 0.5fr 0.5fr 0.5fr',
                        alignItems:'center',
                    }}
                        className="lg:grid hidden gap-4"
                    >
                        <Count count={x.items.length} />
                        <Edit idx={idx} id={x.id} />
                        <DeleteButton id={x.id} name={x.name} o={idx}/>
                        <Link  className="flex items-center justify-center" to={{pathname:`https://laid.pamu.me/playlist/${x.id}`}} target="_blank">
                            <button>
                                <ExternalLink/>
                            </button>
                        </Link>
                    </div>
                    <button className="lg:hidden mr-5 flex items-center justify-center">
                        <Burger/>
                    </button>
            </div>
        )
    }


    const create_playlist = async () => {
        const {data, error} = await supabase
        .rpc('create_new_playlist')
        if(error) throw error
        history.push(`/playlist/${data}`)
    }
    

    return (
        <div className="w-full h-full flex flex-col">
            <div className="h-24 flex flex-row items-center">
                <p className="text-4xl font-bold h-full w-64 flex items-center pl-5">
                    Playlisty
                </p>
                <button
                    onClick={create_playlist}
                    className="ml-auto bg-red-600 h-12 w-36 mr-10 rounded-lg font-bold text-lg">
                    Dodaj playliste
                </button>
            </div>
            {editing ? <PlaylistEdit data={playlists[editing_index]}/> :
            <>
                {!playlists ? null : 
                
                playlists.map((x,idx) => {
                    return (<PlaylistRow idx={idx} key={x.id} x={x}/>)
                })
                
                }
            </>
            
            }
        </div>
    )
}