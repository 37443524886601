import { createClient } from "@supabase/supabase-js";
import { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditIcon, SpinLoading, Trash } from "./Icons";
import { NewVideoDialog } from "./NewVideoDialog";
import { supabase } from "./supabase";

export const PlaylistEdit = ({id}) => {


    const [info, setInfo] = useState(null)
    const [videos, setVideos] = useState(null)
    const [editTitle, setEditTitle] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const supabase_get_data = async (id) => {
        const {data, error} = await supabase
            .from('playlist')
            .select(`
                id,
                name,
                items: playlist_video (
                    id, cda_id, title, thumb, p_order, author, playlist_id, user_id
                )
            `)
            .eq('id',id)
            .single()

        console.log(data)
        if(error) throw error
        setVideos(data.items)
        setInfo({id:data.id, name:data.name})
        
    }


    useEffect(() => {
        supabase_get_data(id)
    }, [])


    const handleOnDragEnd = async (result) => {
        if (!result.destination) return;
    
        const items = Array.from(videos);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        items.forEach((x,idx) => {
            x.p_order = idx+1
        })
        const {data, error} = await supabase.from('playlist_video').upsert(items).eq('playlist_id', info.id)
        if(error) throw error
        setVideos(items);
    }

    const ChangeCDA = (value, idx) => {
        let p = [...videos]
        p[idx].cda_id = value
        setVideos([...p])
    }

    const Thumb = ({thumb, duration}) => {
        return (
            <div className="relative min-w-32 ">
                <img className="rounded-lg" src={thumb}/>
                <div className="absolute text-white bottom-1 right-1" >
                    {duration ? <p className="bg-black bg-opacity-70 px-2 py-1 text-xs rounded-md font-semibold">
                        {duration}
                    </p> : null}

                </div>
            </div>
        )
    }    

    const Title = ({title}) => {
        return (
            <div className="min-w-64 truncate font-semibold  dark:text-white text-black">
                {title}
            </div>
        )
    }


    const add_video_to_playlist = async (video) => {
        video.p_order = videos.length + 1
        video.user_id = supabase.auth.user().id
        video.playlist_id = info.id
        const {data, error} = await supabase
            .from('playlist_video')
            .insert(video,{returning:'minimal'})
            .eq('playlist_id',id)

        if(error) throw error
        window.location.reload()
    }

    const delete_video_from_playlist = async (id, title, o) => {
        if(window.confirm(`Czy na pewno chcesz usunąć z playlisty film ${title}?`)){
            const {data, error} = await supabase
                .from('playlist_video')
                .delete({returning:'minimal'})
                .eq('id',id)
    
            if(error) throw error
            let v = videos
            v.splice(o,1)
            v.forEach((x,idx) => {
                x.p_order = idx+1
            })
            setVideos([...v])
        }
    }

    const set_new_name_to_playlist = async (id, name) => {
        const { data, error } = await supabase
            .from('playlist')
            .update({ name: name })
            .match({ id: id })
        if(error) throw error
        console.log(data)
        console.log(error)
        setEditTitle(false)
    }


    return (
        <div>
            {!info ? <div className="flex items-center justify-center w-full h-screen ">
                <SpinLoading/>
            </div>: 
                <div className="w-full h-full flex flex-col text-black dark:text-white">
                    <div className="h-24 flex flex-row items-center">
                        <div className="flex items-center">
                            {editTitle ? 
                            <div className="flex items-center space-x-2">
                                <input className="
                                    text-3xl font-bold h-full w-136 truncate flex items-center ml-5
                                    dark:text-white
                                    text-black
                                    bg-transparent
                                    border-b-2 py-2
                                    border-gray-700
                                    dark:border-gray-400
                                "   
                                    autoFocus={true}
                                    //onChange={(e) => setTitle(e.target.value)}
                                    onChange={(e) => {
                                        let new_name = e.target.value
                                        let i = info
                                        i.name = new_name
                                        setInfo({...i})
                                    }}
                                    value={info.name}
                                />
                                <div className="pl-4 space-x-4">
                                    <button onClick={() => setEditTitle(false)}  className="font-semibold text-gray-400 text-lg">Anuluj</button>
                                    <button onClick={() => set_new_name_to_playlist(info.id, info.name)}  className="font-semibold text-blue-400 text-lg">Zapisz</button>
                                </div>
                            </div>
                            :
                            <div className="flex items-center space-x-4">
                                <p className="
                                    text-3xl font-bold h-full w-136 truncate flex items-center ml-5
                                    dark:text-white
                                    text-black
                                    bg-transparent
                                    "
                                >
                                    {info.name}
                                </p>
                                <button onClick={() => setEditTitle(!editTitle)}>
                                    <EditIcon/>
                                </button>
                            </div>
                            }
                            
                        </div>
                        <div className="flex ml-auto">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className=" bg-red-600 h-12 w-36 mr-10 rounded-lg font-bold text-lg text-white">
                                Dodaj video 
                            </button>
                            <NewVideoDialog setIsOpen={setIsOpen} isOpen={isOpen} AddVideo={add_video_to_playlist}/>
                        </div>
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters">
                            {(provided) => (
                            <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                                {videos.sort((a,b) => a.p_order-b.p_order).map(({id, title, thumb, cda_id, author}, index) => {
                                    return (
                                    <Draggable key={id} draggableId={id} drop index={index}>
                                        {(provided) => (
                                            <li 
                                                ref={provided.innerRef} 
                                                {...provided.draggableProps} 
                                                {...provided.dragHandleProps}
                                                className="
                                                grid items-center grid-cols-4xcustom gap-4 py-2 px-4
                                                dark:hover:bg-white dark:hover:bg-opacity-8 
                                                hover:bg-black hover:bg-opacity-8 
                                                "
                                            >
                                                <Thumb thumb={thumb}/>
                                                <Title title={title}></Title>

                                                <span className="text-center  font-semibold  dark:text-white text-black">
                                                {cda_id}
                                                </span>
                                                <button className="text-center" onClick={() => delete_video_from_playlist(id, title, index)}>
                                                    <Trash/>
                                                </button>
                                            </li>
                                        )}
                                    </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

            }
        </div>
      );
}
