import React,{ useContext } from "react";
import { ThemeContext } from "../context/dm";

export const LoginLogo = () => {

    const theme = useContext(ThemeContext);

    const isDark = theme.state;

    return (
        <>
            <div style={{
                background:'linear-gradient(-45deg, #FAB85C, #E053A8, #6E87F5, #59DE90, #FFF266)',
                backgroundSize: '500% 500%',
                animation: 'gradient 10s ease-in-out infinite',
                filter: isDark ? 'invert(70%)' : 'invert(0%)'
            }}
                className="flex flex-col justify-center items-cente absolute sm:w-108  w-full  h-48 sm:rounded-t-lg drop-shadow-lg"
            />

            <div className="flex flex-col items-center justify-center w-full min-h-48 z-10">
                <p className="font-bold text-6xl">
                    {process.env.REACT_APP_TITLE}
                </p>    
                <p className="font-medium text-xl">
                    Zaloguj się
                </p>
            </div>
        </>
    )
}