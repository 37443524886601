import { createContext, useReducer } from "react";

export const SessionContext  = createContext()

const initialState  = null

const SessionReducer = (state, action) => {
    console.log(state,action)
    switch(action.type){
        case 'SIGNED_IN':
            return action.user_data;
        case 'SIGNED_OUT':
            return null
        default:
            return state
    }
}

export const SessionProvider = (props) => {
    const [state, dispatch] = useReducer(SessionReducer, initialState);

    return <SessionContext.Provider value={{ state, dispatch }}>{props.children}</SessionContext.Provider>;
}