import { useContext } from "react";
import { ThemeContext } from "../context/dm";
import { SwitchMode } from "../components/DMSwitch"
import { LoginLogo } from "../components/LoginLogo";
import { DiscordAuth } from "../components/auth/discord";

export const Login = () => {

    return (
        <div className=" w-full h-screen flex items-center justify-center">
            <div className="flex flex-col sm:w-108 sm:h-124 w-full h-full bg-white dark:bg-opacity-8 sm:rounded-lg shadow-lg" >
                <LoginLogo/>
                <div className="flex flex-col justify-center items-center w-full h-full" >
                    <DiscordAuth/>
                </div>
            </div>
            <div className="absolute bottom-5 right-5 ">
                <SwitchMode/>
            </div>
        </div>
    )
}