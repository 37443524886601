import { Dialog, Transition } from "@headlessui/react";
import { info } from "autoprefixer";
import {Fragment, useState } from "react";

export const NewVideoDialog = ({isOpen, setIsOpen, AddVideo}) => {
    const [new_video, setNew_video] = useState(null)
    const [s, ds] = useState('')

    const get_id = (link) => {
        const regex = new RegExp(/https?:\/\/(?:(?:www\.)?cda\.pl\/video|ebd\.cda\.pl\/[0-9]+x[0-9]+)\/(?<id>[0-9a-z]+)/)
        return regex.test(link) ? link.match(regex)[1] : null
    }

    const get_info = async (id) => {
        if(!id) return null
        let f = null
        try{
            f = await fetch(` ${process.env.REACT_APP_API_URL}/info/${id}`)
            const f_json = await f.json()
            return f_json
        }
        catch(e){
            return {'code':f.status, 'msg':f.statusText, 'data':null}
        }

    }
    
    const add_video = async (link) => {
        const id = get_id(link)
        if(!id) return null

        const info = await get_info(id)
        if(info.code !== 200){
            alert(`${info.code}: ${info.msg}`)
        } else {
            setNew_video(info.data)
        }
    }

    const [viewPlayer, setViewPlayer] = useState(false)

    const call_two_onclick = (obj) => {
        AddVideo(obj)
        setIsOpen(false)
        ds('')
        setNew_video(null)
    }

    return (
        <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto"
          onClose={() => setIsOpen(false)}
        >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-md  my-8 overflow-hidden text-left align-middle transition-all transform bg-bg_dark shadow-xl rounded-2xl">
                        <div className="bg-white dark:bg-opacity-20 p-4">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                            >
                                Dodaj video
                            </Dialog.Title>
                                <div className="flex mt-2 mb-2">
                                    <input value={s} onChange={(e) => ds(e.target.value)} className="dark:bg-white dark:bg-opacity-10 dark:text-white shadow-md outline-none w-full h-12 px-2 md:rounded-l-md md:rounded-r-none rounded-t-md" placeholder="Link..."></input>
                                    

                                    <button 
                                        onClick={() => add_video(s)}
                                        className="transition duration-500 ease-in-out
                                        shadow-md h-12 px-3 py-2 font-semibold
                                        bg-blue-500 hover:bg-blue-600 
                                        md:rounded-r-md md:rounded-l-none rounded-b-md text-white">Sprawdź</button>
                                </div>
                                {!new_video ? null :
                                <div className="flex flex-col space-y-2">
                                    {viewPlayer ?
                                        <div className=" w-full">
                                            <iframe
                                                className="w-full rounded-md"
                                                style={{aspectRatio:"16/9"}}
                                                src={`${process.env.REACT_APP_API_URL}/player/${get_id(s)}`}
                                                allow="autoplay"
                                                frameBorder="0"
                                                allowFullScreen
                                            />
                                        </div>
                    
                                    :
                    
                                        <div className="relative md:w-full">
                                            <img className="rounded-lg" src={new_video.thumb}/>
                                            <div className="absolute text-white font-semibold top-0 h-24 w-full rounded-t-lg" style={{background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)'}}>
                                                <p className="px-2 py-2">
                                                    {new_video.title}
                                                </p>
                                            </div>
                                            <div className="absolute text-white bottom-3 right-3" >
                                                <p className="bg-black bg-opacity-70 px-2 py-1 rounded-md font-semibold">
                                                    {new_video.duration}
                                                </p>
                                            </div>
                                            <button onClick={() => setViewPlayer(true)} className="absolute rounded-full h-12 w-12 flex items-center justify-center " style={{top:'50%', left:'50%', transform:'translate(-50%,-50%)', backgroundColor:'#00b3ff'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-player-play" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="#ffffff" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round">
                                                    <path d="M7 4v16l13 -8z" />
                                                </svg>
                                            </button>
                                        </div>
                                    
                                    }
                                    <div className="flex space-x-2 w-full">
                                        <button
                                        className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 "
                                        onClick={() => setIsOpen(false)}
                                        >Anuluj</button>
                                        <button
                                        className="w-full inline-flex justify-center px-4 py-2 text-sm font-medium text-white  bg-green-500 rounded-md hover:bg-green-600 "
                                        onClick={() => call_two_onclick({title:new_video.title, thumb:new_video.thumb, cda_id:new_video.id, author:new_video.author})}
                                        >Dodaj do playlisty</button>

                                    </div>
                                </div>
                                }

                        </div>

                    </div>
                </Transition.Child>
        </Dialog>
      </Transition>
    )
}