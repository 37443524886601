import React,{ useContext,  } from "react";
import { ThemeContext } from "../context/dm";

export const DBLogo = React.memo(({w, h}) => {
    const theme = useContext(ThemeContext);

    const isDark = theme.state;
    return (
        <div className={`flex justify-center items-center w-full h-${h}`}>
            <span className="xl:block hidden font-bold text-4xl z-10">
                {process.env.REACT_APP_TITLE}
            </span>
            <span className="xl:hidden font-bold text-4xl z-10">
                {process.env.REACT_APP_TITLE[0]}
            </span>
            <div style={{
                background:'linear-gradient(-45deg, #FAB85C, #E053A8, #6E87F5, #59DE90, #FFF266)',
                backgroundSize: '500% 500%',
                animation: 'gradient 10s ease-in-out infinite',
                filter: isDark ? 'invert(70%)' : 'invert(0%)'
            }}
                className={`absolute xl:w-64 w-24 h-${h}`}
            />

        </div>
    )
})