import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Dashboard } from "../pages/dashboard"
import { PlaylistEdit } from "./PlaylistEdit"
import { PlaylistList } from "./PlaylistList"
import { VideoList } from "./VideoList"

export const Playlist = () => {
     
    const location = useLocation()
    const history = useHistory()
    const [p, sp] = useState(null)
    const [id, setId] = useState(null)
    useEffect(() => {
        const path = location.pathname.split('/')
        path.shift()
        console.log(path)
        if(path[0] === 'video'){
            sp('video')
        } else if (
            path[0] === 'playlist' &&
            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(path[1])
        ) {
            sp('playlist')
            setId(path[1])
        } else if (path[0] === 'playlist'){
            sp('playlist')
            setId(null)
        } else {
            history.push('/')
        }
    },[location.pathname])
    return (
        <>
            {id ? <PlaylistEdit id={id}/> : p === 'playlist' ? <PlaylistList/> : <VideoList/>}
        </>
    )
}