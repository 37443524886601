import { SwitchMode } from "../components/DMSwitch"
import { Burger, SignOut, PlaylistIcon, VideoIcon } from "../components/Icons"
import React,{ useContext, useEffect, useState,  } from "react";
import { DBLogo } from "../components/DBLogo";
import { VideoList } from "../components/VideoList";
import { useHistory, useLocation } from "react-router-dom";
import { Playlist } from "../components/Playlist";
import { SessionContext } from "../context/session";
import { supabase } from "../components/supabase";

export const Dashboard = () => {


    
    const history = useHistory()
    const location = useLocation()

    const url = "https://cdn.discordapp.com/avatars/206533377820590082/45dd4057010f73fa2d35ec3e847affd1.png?size=1024"

    const [activeTab, setActiveTab] = useState("video")

    const user_data = useContext(SessionContext).state

    useEffect(() => {
        const path = location.pathname.split('/')
        path.shift()
        if(path[0] === 'video'){
            setActiveTab('video')
        } else if (
            path[0] === 'playlist' &&
            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(path[1])
        ) {
            setActiveTab('playlist')
            //setId(path[1])
        } else if (path[0] === 'playlist'){
            setActiveTab('playlist')
        } else {
            history.push('/')
        }
    },[location.pathname])

    const tabs = [
        {
            name:"Filmy",
            icon: <VideoIcon/>,
            id:"video"
        },
        {
            name:"Playlisty",
            icon: <PlaylistIcon/>,
            id:"playlist"
        }
    ]

    const TabButton = ({icon, name, id}) => {

        return (
            <button 
            onClick={() => {
                history.push(`/${id}`)
                setActiveTab(id)
            }}
            className={`
                ${activeTab === id ? 'hover:text-blue-700 dark:text-blue-500 text-blue-500' : ''}
                px-5 py-2 font-bold h-16 w-full flex xl:justify-start justify-center items-center space-x-5 
                hover:text-blue-700 dark:hover:text-blue-500
                hover:bg-black hover:bg-opacity-10 
                transition ease-in-out duration-100 dark:text-white text-black

            `}>
                {icon}
                <span className="hidden xl:block">
                    {name}
                </span>
            </button>
        )
    }


    const Avatar = ({nick, avatar}) => {
        return (
            <button className="w-full h-16 flex items-center space-x-5 px-5 hover:bg-black hover:bg-opacity-20 ">
                <img src={avatar} className="rounded-full h-12" />
                <span className="text-xl font-semibold dark:text-white text-black">
                    {nick}
                </span>
            </button>
        )
    }

    const signout = async () => {
        const { data, error } = await supabase.auth.signOut()
        console.log(data, error)
        history.push('/login')
    }

    return (
        <div className="flex flex-row w-full h-screen bg-white dark:bg-opacity-5 ">
            <div className="h-full xl:min-w-64 w-24 flex flex-col ">
                <DBLogo w="64 w-24" h="24"/>
                <div className="xl:min-w-64 w-24">
                    {tabs.map(x => {
                        return ( 
                            <TabButton key={x.id} icon={x.icon} name={x.name} id={x.id}/>
                        )
                    })}
                </div>
                <div className="mt-auto hidden xl:flex flex-col justify-center items-center pb-5 space-y-5">
                    <Avatar nick={user_data?.full_name} avatar={user_data?.avatar_url}/>
                    <SwitchMode/>
                    <button
                        onClick={signout}
                        className="
                            px-5 py-2 font-bold h-full w-full flex justify-center items-center space-x-2
                            hover:text-blue-700 dark:hover:text-blue-500
                            hover:bg-black hover:bg-opacity-10 
                            transition ease-in-out duration-100 dark:text-white text-black
                        "
                    >
                        <span>Wyloguj się</span>
                        <SignOut></SignOut>
                    </button>
                </div>
                <button className="mt-auto xl:hidden flex justify-center items-center py-5">
                    <Burger/>
                </button>
            </div>
            <div className="h-full w-full border-l-2 dark:border-gray-600">
                    {activeTab === 'playlist' ? <Playlist/> : <VideoList/>}
            </div>
        </div>
    )
}