import { useContext, useEffect } from "react";
import { Route, Switch, useHistory} from "react-router-dom";
import { Login } from "./pages/login";
import { Dashboard } from "./pages/dashboard";
import { supabase } from "./components/supabase";
import { SessionContext } from "./context/session";

const App = () => {
    
    const history = useHistory()
    const setSesstion = useContext(SessionContext)
    useEffect(() => {

        const isLogged = supabase.auth.user()
        console.log(isLogged)
        if(!isLogged){
            history.push('/login')
        } else {
            setSesstion.dispatch({
                type:'SIGNED_IN',
                user_data: isLogged.user_metadata
            })
        }
        document.documentElement.classList.add('dark')
        document.body.classList.add('bg-bg_dark')
        document.body.classList.add('dark:bg-opacity-90')
        document.body.classList.add('bg-opacity-10')
    },[])


    return (
        <div >
            <div className=" text-white w-full min-h-screen">
                <div className="flex justify-center items-center m-auto">
                    <Switch>
                        <Route exact path="/login">
                            <Login/>
                        </Route>
                        <Route path={["/"]}>
                            <Dashboard/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default App;
